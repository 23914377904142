import { PatchModel } from '@nexus/models/patch.model';
interface Supplier {
  id?: any;
  _id?: any;
  crm: string;
  name: string;
  code: string;
  logo?: string;
  active?: boolean;
  abbreviation: string;
  patches: Array<string>;
  timeSlots: Array<string>;
  jobTypes: Array<string>;
  appointmentTypes: Array<string>;
  dccs?: any;
  capacityCaps?: Array<Date>;
  subcontractors?: Array<string>;
}

interface Dcc {
  code: string;
  name: string;
  default?: boolean;
}

class SupplierModel {
  constructor(
    public crm: string,
    public name: string,
    public code: string,
    public abbreviation: string,
    public patches: Array<string>,
    public timeSlots: Array<string>,
    public id?: string,
    public logo?: string,
    public dccs?: Object,
    public capacityCaps?: Array<Date>,
  ) {}
}
interface NewSupplierData {
  name: string;
  abbreviation: string;
  code: string;
}

export { SupplierModel, NewSupplierData, Supplier, Dcc };
