/* Enumeration Model */
enum FuelType {
  GAS = 'GAS',
  DUAL = 'DUAL',
  ELECTRIC = 'ELECTRIC',
  NONE = 'NONE',
}

enum JobTypeEnum {
  'DUAL_FUEL_SMETS_2' = 'DUAL_FUEL_SMETS_2',
  'ELECTRIC_SMETS_2' = 'ELECTRIC_SMETS_2',
  'GAS_SMETS_2' = 'GAS_SMETS_2',
}

/**
 * @deprecated The class should not be used
 */
class JobTypeModel {
  constructor(
    public name: string,
    public code: string,
    public fuelType: FuelType,
    public id?: string,
    public mediumPressure?: boolean,
    public economySeven?: boolean,
    public threePhase?: boolean,
    public minEngineers?: number,
    public customSlotChangeReasons?: string[],
  ) {}
}

interface OptionalBookingProps {
  supplyStartDate?: boolean;
  accountType?: boolean;
  dataConsent?: boolean;
  mpxn?: boolean;
  msn?: boolean;
}

interface MandatoryBookingProps {
  sscCode?: boolean;
  profileClass?: boolean;
}

interface JobType {
  smets2?: any;
  eusr?: boolean
  name: string;
  code: string;
  fuelType: string;
  id?: string;
  _id?: string;
  mediumPressure?: boolean;
  economySeven?: boolean;
  threePhase?: boolean;
  currentTransformer?: boolean;
  minEngineers?: number;
  qualifications?: string[];
  licenses?: string[];
  durationDays?: number;
  customSlotChangeReasons?: string[];
  preDisconnectionVisit?: boolean;
  optionalBookingProps?: OptionalBookingProps;
  mandatoryBookingProps?: MandatoryBookingProps;
  hiddenBookingProps?: OptionalBookingProps;
  newConnection?: boolean;
  isSip?: boolean;
}

const normalizeJobTypeOptions = (rawJobTypes: JobType[], useId?: boolean) => {
  return rawJobTypes.map((rawJobTypesItem) => {
    return {
      value: useId ? rawJobTypesItem.id : rawJobTypesItem.code,
      name: rawJobTypesItem.name,
    };
  });
};

export { JobTypeModel, JobType, FuelType, normalizeJobTypeOptions, JobTypeEnum };
